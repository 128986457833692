<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewPageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-add-new-page-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{
            typeForm === 'add'
              ? 'Add New'
              : typeForm === 'edit'
              ? 'Edit'
              : 'Detail'
          }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nama Store"
            rules="required"
          >
            <b-form-group label="Nama Store" label-for="name_store">
              <b-form-input
                id="warehouse-code"
                v-model="form.name_store"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nama Store"
                :readonly="!canEdit"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="No. WhatsApp"
            rules="required|numeric"
          >
            <b-form-group label="No. WhatsApp" label-for="wa_number">
              <b-form-input
                id="wa_number"
                v-model="form.wa_number"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="No. WhatsApp"
                :readonly="!canEdit"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Alamat"
            rules="required"
          >
            <b-form-group label="Alamat" label-for="address">
              <b-form-textarea
                id="address"
                v-model="form.address"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Alamat"
                :readonly="!canEdit"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Link GMap"
            rules="required"
          >
            <b-form-group label="Link GMap" label-for="gmap_link">
              <b-form-input
                id="gmap_link"
                v-model="form.gmap_link"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Link GMap"
                :readonly="!canEdit"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div v-if="typeForm !== 'show'" class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <span>Submit</span>
              <b-spinner v-if="isLoading" small class="ml-1" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BModal,
  BFormSelect,
  BFormDatepicker,
  BSpinner,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import inject, { ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import { typeVoucherOptions } from '@core/utils/constant'
import store from '@/store'

export default {
  components: {
    Password: () => import('vue-password-strength-meter'),
    DatePicker: () => import('vue2-datepicker'),
    BSidebar,
    BForm,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BFormDatepicker,
    BButton,
    vSelect,
    BSpinner,
    BFormCheckbox,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPageSidebarActive',
    event: 'update:is-add-new-page-sidebar-active',
  },
  props: {
    isAddNewPageSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeForm: {
      type: String,
      default: 'add',
    },
    data: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    const blankFormData = {
      name_store: null,
      wa_number: null,
      address: null,
      gmap_link: null,
    }

    const canEdit = ref(true)

    const form =
      props.data !== null
        ? ref(props.data)
        : ref(JSON.parse(JSON.stringify(blankFormData)))
    const resetpageData = () => {
      form.value = JSON.parse(JSON.stringify(blankFormData))
    }

    watch(
      () => props.isAddNewPageSidebarActive,
      (newValue, prevValue) => {
        if (props.typeForm === 'edit') {
          canEdit.value = true
          form.value = props.data
        } else if (props.typeForm === 'show') {
          canEdit.value = false
          form.value = props.data
        } else {
          canEdit.value = true
          form.value = JSON.parse(JSON.stringify(blankFormData))
        }
      }
    )

    const onSubmit = () => {
      emit('onSubmit', form.value)
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpageData)

    return {
      tglLahir: null,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      canEdit,
      props,

      form,
      // onSearch,
      // filter,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
