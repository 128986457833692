<template>
  <div>
    <side-form
      :is-add-new-page-sidebar-active.sync="isShowFormAdd"
      :is-loading="isLoading"
      :type-form="typeForm"
      :data="serviceCenterData"
      @onSubmit="onSubmit"
    />
    <b-card>
      <own-table
        ref="serviceCenterTable"
        name-table="service-center"
        :table-columns="columns"
        :use-custom-filter="true"
        :custom-query-filter="queryFilter"
        :export-excel="false"
        @searchFieldOnChange="searchOnChange"
        @evt-add="showAddForm"
      >
        <template #cell(no)="data">
          <span>{{ data.data.index + 1 }}</span>
        </template>
        <template #cell(gmap_link)="data">
          <a :href="data.data.item.gmap_link" target="_blank">{{
            data.data.item.gmap_link
          }}</a>
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.data.item.created_at) }}</span>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              menu-class="position-absolute"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="edit(data.data.item)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="onDelete(data.data.item.id)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import { BCard, BBadge, BDropdown, BDropdownItem, BLink } from 'bootstrap-vue'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import store from '@/store'
import { formatDate, formatCurrency } from '@/@core/utils/filter'
import SideForm from './components/SideForm.vue'

export default {
  components: {
    BCard,
    OwnTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    SideForm,
    BLink,
  },
  setup() {
    const serviceCenterTable = ref(null)
    const columns = ref([
      {
        label: 'No',
        key: 'no',
      },
      {
        label: 'Nama Store',
        key: 'name_store',
        sortable: true,
      },
      {
        label: 'No. WhatsApp',
        key: 'wa_number',
        sortable: true,
      },
      {
        label: 'Alamat',
        key: 'address',
        sortable: true,
      },
      {
        label: 'GMap',
        key: 'gmap_link',
        sortable: true,
      },
      {
        label: 'Created At',
        key: 'created_at',
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const isLoading = ref(false)
    const isShowFormAdd = ref(false)
    const queryFilter = ref('')
    const search = ref('')
    const typeForm = ref('add')
    const serviceCenterData = ref(null)
    const onSubmit = formData => {
      isLoading.value = true
      serviceCenterTable.value.submit(formData, isSuccess => {
        if (isSuccess) {
          isShowFormAdd.value = false
        }
        isLoading.value = false
      })
    }

    const statusVariant = status => {
      if (status === 1) {
        return 'success'
      }
      return 'danger'
    }

    const showAddForm = () => {
      isShowFormAdd.value = true
      serviceCenterData.value = null
    }

    const searchOnChange = value => {
      queryFilter.value = `&q=${value}`
    }

    const edit = data => {
      typeForm.value = 'edit'
      serviceCenterTable.value.onEdit()
      serviceCenterData.value = data
      isShowFormAdd.value = true
    }

    const onDelete = id => {
      serviceCenterTable.value.onDelete(id)
    }
    // const formatDateTime = date => formatDate(date)
    return {
      columns,
      queryFilter,
      serviceCenterTable,
      statusVariant,
      formatDate,
      formatCurrency,

      showAddForm,
      isShowFormAdd,

      searchOnChange,

      onSubmit,

      isLoading,
      edit,
      serviceCenterData,
      typeForm,
      onDelete,
    }
  },
}
</script>
